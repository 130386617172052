<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import { validationMixin } from "vuelidate";
import { required, helpers } from 'vuelidate/lib/validators'
import errorHandler from '../../../helpers/errorHandler';
const phoneRegex = helpers.regex('phoneNumberRegex',/^201[0-2,5]{1}[0-9]{8}$/);

/**
 * Starter component
 */
export default {
  page: {
    title: "Stage Form",
  },
  data() {
    return {
      title: "Stage",
      mixins: [validationMixin],
      items: [
        {
          text: "Work center",
          href: "/",
        },
        {
          text: "Stages",
          href: "/years",
        },
        {
          text: "Edits",
          active: true,
        },
      ],
      submit: false,
      backendError: false,
      type: null,
      form:{
        number: "",
        description: "",
        id: null
      }
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  validations:{
    form:{
      number: {
        required
      },
      description:{
        required
      },
    }
  },
  methods:{
    handleSubmit() {
      this.submit = true;
      this.$v.$touch();
      if(!this.$v.$anyError){
        // requests for the create
        this.$store.dispatch("year/addYear",this.form).then(
           ()=>{
            this.$router.push('/years');
          }
        ).catch((err)=>{
          errorHandler.methods.error(err);
        });
      }
      
    },
    handleUpdate(){
      // this.$store.commit("accounts/CLEAR_ERRORS");
      this.submit = true;
      this.$v.$touch();
      if(!this.$v.$anyError){
        // requests for the create
        this.$store.dispatch("year/editYear",this.form).then(
           ()=>{
            this.$router.push('/years');
          }
        ).catch((err)=>{
          errorHandler.methods.error(err);
        });
      }
    }
  },
  async created(){
    if(this.$route.params.id){
        this.type = 'edit';
        await this.$store.dispatch('year/getYearInfo',this.$route.params.id).then( token =>{
            this.form.number = token.number;
            this.form.description = token.description;
            this.form.id = this.$route.params.id;
        }).catch( err =>{
            errorHandler.methods.errorMessage(err);
        })
    }else{
        this.type = 'create';
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body">
            <div>
                <div>
                  <div>
                    <h2 v-if="type == 'create'">Create stage</h2>
                    <h2 v-if="type == 'edit'">Edit stage</h2>
                    <hr>
                  </div>
                <form action="#" @submit.prevent="handleSubmit">
                <div class="row">
                    <div class="col-md-6">
                    <b-form-group
                        id="input-group-2"
                        label="Level:"
                        label-for="input-D"
                    >
                        <input v-model="form.number" type="text" name="name" class="form-control" :class="{ 'is-invalid': submit && $v.form.number.$error }" placeholder="Enter level" />
                        <div v-if="submit && $v.form.number.$error" class="invalid-feedback">
                            <span v-if="!$v.form.number.required">This Level is required.</span>
                            
                        </div>
                        <div v-if="backendError  " class="text-danger">
                        <span>{{ errorsList.name[0] }}</span>
                        </div>
                    </b-form-group>
                    </div>
                    <div class="col-md-6">
                    <b-form-group
                        id="input-group-phone"
                        label="Stage:"
                        label-for="input-phone"
                    >
                        <div class="row">
                        <div class="col-xl-12">
                            <b-input-group>
                            <input v-if=" type == 'create' " v-model="form.description" type="text" name="phone_number" class="form-control" :class="{ 'is-invalid': submit && $v.form.description.$error }" placeholder="Enter stage " />
                            <input v-if=" type == 'edit' && form.description" v-model="form.description" type="text" name="phone_number" class="form-control" :class="{ 'is-invalid': submit && $v.form.description.$error }" placeholder="Enter stage" />
                            <div v-if="submit && $v.form.description.$error" class="invalid-feedback">
                                <span v-if="!$v.form.description.required">This phone is required.</span>
                            </div>
                            </b-input-group>
                            <div v-if="backendError" class="text-danger">
                                <span>{{ errorsList['phone_numbers.0'][0] }}</span>
                            </div>
                        </div>
                        </div>
                    </b-form-group>
                    </div>
                </div>
                </form>
                </div>
                <hr />
                <div class="float-right">
                <button class="btn btn-outline-success" type="submit" @click="handleSubmit" v-if="type == 'create'">Submit</button>
                <button class="btn btn-outline-success" type="submit" @click="handleUpdate" v-if="type == 'edit'">Update</button>
                <b-button variant="outline-danger" class="ml-2 aline-right" @click="$router.go(-1)"
                >Cancel</b-button
                >
                </div>
          </div>  
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
#input-group-1 {
  display: inline;
}
</style>